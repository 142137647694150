
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getStayList } from '@/api/stay';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import moment from 'moment';
import { getOptionItemList } from '@/api/optionItem';
import { getStayCategoryList } from '@/api/stayCategory';
import { getStayOptionTypeList } from '@/api/stayOptionType';
import { addFavoriteItem, deleteFavoriteItem } from '@/api/favoriteItem';
import { UserModule } from '@/store/user';
import InfiniteLoading from 'vue-infinite-loading';
import Calendar from '@/components/Calendar/index.vue';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { LatLng } from '../latlng';

@Component({
  components: {
    InfiniteLoading,
    Calendar,
  },
})

export default class extends Vue {
  @Watch('searchPrice', { deep: true })
  private handleChangeSearchPrice() {
    if (this.searchPrice && this.searchPrice.length === 2) {
      this.listQuery.startPrice = this.searchPrice[0];
      this.listQuery.endPrice = this.searchPrice[1];
    }
  }

  @Watch('filterOptionVisible')
  private handleChangeFilterOptionVisible() {
    if (!this.filterOptionVisible) {
      (this.$refs.priceSlider as any).$refs.button1.handleMouseLeave();
      (this.$refs.priceSlider as any).$refs.button2.handleMouseLeave();
      document.documentElement.classList.remove('noscr');
    } else {
      document.documentElement.classList.add('noscr');
    }
  }

  mounted() {
    this.getRegionList();
    this.getStayCategoryList();
    this.getStayOptionTypeList();
    this.setUserLocation();
    this.searchPrice.toLocaleString();
  }

  get currentColor(): string {
    const date = new Date();
    const hour = date.getHours();

    if (hour >= 7 && hour < 18) {
      return 'color07';
    }
    if (hour >= 18 && hour < 20) {
      return 'color18';
    }
    if (hour >= 20 && hour < 24) {
      return 'color20';
    }
    if (hour >= 0 && hour < 4) {
      return 'color20';
    }
    if (hour >= 4 && hour < 7) {
      return 'color4';
    }
    return '';
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private filterDefault = {
    date: '',
    personnel: '성인2명 / 아동1명',
    personnelAdult: 2,
    personnelChild: 1,
    village: '',
  }

  private isNoMore = false;

  private sortList = [
    { label: '추천순', value: 'recommand' },
    { label: '거리순', value: 'distance' },
    { label: '평점높은순', value: 'highScore' },
    { label: '리뷰많은순', value: 'highReview' },
  ]

  private datePickerVisible = false;

  private filterOptionVisible = false;

  private filterSortDrawer = false;

  private listQuery: any = {
    searchValue: this.$route.query.searchValue,
    page: 1,
    size: 20,
    startDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
    endDate: this.$route.query.endDate ? this.$route.query.endDate : moment().add(1, 'days').format('YYYY-MM-DD'),
    lat: this.$route.query.lat ? this.$route.query.lat : '37.566826',
    lng: this.$route.query.lng ? this.$route.query.lng : '126.9786567',
    // lat: this.$route.query.lat ? this.$route.query.lat : '34.81762395218535', // 남해마늘연구소
    // lng: this.$route.query.lng ? this.$route.query.lng : '127.92101314625813', // 남해마늘연구소
    sort: 'distance',
    region: this.$route.query.region ? this.$route.query.region : '',
    adult: this.$route.query.adult ? this.$route.query.adult : 2,
    child: this.$route.query.child ? this.$route.query.child : 0,
    startPrice: 0,
    endPrice: 1000000,
    categories: '',
    options: '',
  }

  private searchPrice = [0, 1000000];

  private formQuery: any = {
    startDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
    endDate: this.$route.query.endDate ? this.$route.query.endDate : moment().add(1, 'days').format('YYYY-MM-DD'),
    adult: this.$route.query.adult ? this.$route.query.adult : 2,
    child: this.$route.query.child ? this.$route.query.child : 0,
    filterCnt: 0, /* 검색영역의 선택한 필터 갯수 기본값 */
  }

  private stayList = [];

  private nearStayList = [];

  private regionList = [];

  private stayCategoryList: any = [];

  private loading = true;

  private nearLoading = true;

  private searchCategories = [];

  private searchOptions = [];

  private optionTypeList: any = [];

  private searchAsync = false;

  addSortOption(label: string, value: string) {
    this.sortList.push({ label, value });
  }

  removeSortOptionByValue(value: string) {
    this.sortList = this.sortList.filter((item) => item.value !== value);
  }

  private datePickerChange() {
    this.datePickerVisible = !this.datePickerVisible;
  }

  private handleDatePickerChange() {
    if (!this.datePickerVisible) {
      document.documentElement.classList.remove('noscr');
    } else {
      document.documentElement.classList.add('noscr');
    }
  }

  private getStayList() {
    this.loading = true;
    getStayList(this.listQuery).then((res) => {
      this.loading = false;
      this.searchAsync = false;
      this.stayList = this.stayList.concat(res.data.content);
      console.log(this.stayList);
      // 첫번째 페이지 이면서 거리순 조회 일 경우 첫번쨰 아이템 삭제
      if (this.listQuery.page <= 0 && this.listQuery.sort === 'distance') {
        this.stayList.shift();
        this.stayList.shift();
      }
    }).catch(() => {
      this.$message.error('숙소를 불러오는데 실패했습니다.');
      this.loading = false;
      this.searchAsync = false;
    });
  }

  private getTopNearStay() {
    const params = {
      ...this.listQuery,
      page: 1,
      size: 2,
      sort: 'distance',
    };
    getStayList(params).then((res) => {
      this.nearLoading = false;
      this.nearStayList = res.data.content;
    }).catch(() => {
      this.$message.error('내 위치 숙소를 불러오는데 실패했습니다.');
      this.nearLoading = false;
    });
  }

  // 남해군의 좌표
  // 북서
  private northWest: LatLng = { lat: 34.9203, lng: 127.8795 };

  // 북동
  private northEast: LatLng = { lat: 34.9333, lng: 128.0967 };

  // 남서
  private southWest: LatLng = { lat: 34.6795, lng: 127.8352 };

  // 남동
  private southEast: LatLng = { lat: 34.7412, lng: 128.1107 };

  private isWithinBounds(point: LatLng): boolean {
    const latMin = Math.min(this.southWest.lat, this.southEast.lat);
    const latMax = Math.max(this.northWest.lat, this.northEast.lat);
    const lngMin = Math.min(this.northWest.lng, this.southWest.lng);
    const lngMax = Math.max(this.northEast.lng, this.southEast.lng);

    return point.lat >= latMin && point.lat <= latMax && point.lng >= lngMin && point.lng <= lngMax;
  }

  // 남해군인지 외부인지 비교를 위한 변수
  private latLng = { lat: 0, lng: 0 };

  private async setUserLocation() {
    // 사용자의 위치(위도, 경도)를 쿠키에서 받아옴
    const lat = getLat();
    const lng = getLng();
    // const lat = 34.79907680340181;
    // const lng = 127.88040894293046;

    // 남해군인지 외부인지 비교를 위한 변수
    this.latLng.lat = lat;
    this.latLng.lng = lng;

    if (lat && lng) { // 사용자 위치가 쿠키에 있으면 해당 위치로 리스트 출력
      if (this.isWithinBounds(this.latLng)) { // 사용자 위치가 남해 내부에 있으면
        this.listQuery.lat = lat;
        this.listQuery.lng = lng;

        this.removeSortOptionByValue('recommand');

        this.getStayList();
        this.getTopNearStay();
      } else { // 사용자 위치가 쿠키에 있고 남해 외부에 있으면
        this.listQuery = {
          ...this.listQuery,
          sort: 'recommand',
          lat,
          lng,
        };
        this.getStayList();
        this.getTopNearStay();
      }
    } else { // 사용자 위치가 쿠키에 없으면 사용자 위치를 요청
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // 남해군인지 외부인지 비교를 위한 변수
          this.latLng.lat = position.coords.latitude;
          this.latLng.lng = position.coords.longitude;

          if (this.isWithinBounds(this.latLng)) { // 사용자 위치가 쿠키에 없고, 사용자 정보가 남해군 내부에 있으면
            this.removeSortOptionByValue('recommand');
            this.listQuery = {
              ...this.listQuery,
              lat: this.latLng.lat,
              lng: this.latLng.lng,
            };
            setLat(this.latLng.lat);
            setLng(this.latLng.lng);
          } else { // 사용자 위치가 쿠키에 없고, 사용자 정보가 남해군 외부에 있으면
            this.listQuery = {
              ...this.listQuery,
              sort: 'recommand',
              lat,
              lng,
            };
          }
          this.getStayList();
          this.getTopNearStay();
        },
        (error) => {
          this.listQuery = {
            ...this.listQuery,
            sort: 'recommand',
            lat,
            lng,
          };
          this.getStayList();
          this.getTopNearStay();
        },
      );
    }
  }

  private getRegionList() {
    getOptionItemList('REGION').then((res) => {
      this.regionList = res.data;
    });
  }

  private getRemainRoomClass(remainRoom: any) {
    let status = 'status04';
    if (remainRoom > 2) status = 'status03';
    if (remainRoom > 4) status = 'status02';
    if (remainRoom > 7) status = 'status01';
    return status;
  }

  private handleClickSort(sort: string) {
    this.loading = true;
    this.stayList = [];
    this.filterSortDrawer = false;
    this.listQuery.sort = sort;
    this.listQuery.page = 0;
    this.isNoMore = false;
    this.getStayList();
  }

  private getSortLabel() {
    let label = '';
    const idx = this.sortList.findIndex((sort: any) => sort.value === this.listQuery.sort);
    if (idx > -1) label = this.sortList[idx].label;
    return label;
  }

  private handleResetFormQuery() {
    this.formQuery = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      adult: 2,
      child: 0,
      filterCnt: 0, /* 검색영역의 선택한 필터 갯수 기본값 */
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.removeActiveClass();
  }

  private handleApplyFormQuery() {
    this.listQuery = {
      ...this.listQuery,
      ...this.formQuery,
    };
    this.datePickerVisible = false;
    this.handleSearch();
  }

  private async handleSearch() {
    this.loading = true;

    if (this.searchAsync) {
      this.$message.error('검색중 입니다. 잠시만 기다려 주십시오.');
    } else {
      this.searchAsync = true;
      this.stayList = [];
      this.listQuery.categories = this.searchCategories.join(',');
      this.listQuery.options = this.searchOptions.join(',');
      this.listQuery.page = 1;
      this.isNoMore = false;
      this.getStayList();
      this.getTopNearStay();
    }
  }

  private getStayCategoryList() {
    getStayCategoryList().then((res) => {
      this.stayCategoryList = res.data;
    });
  }

  private getStayOptionTypeList() {
    getStayOptionTypeList().then((res) => {
      this.optionTypeList = res.data;
    });
  }

  private handleViewStay(idx: any) {
    this.$router.push({
      name: 'StayDetail',
      params: { stayIdx: idx },
      query: {
        startDate: this.listQuery.startDate,
        endDate: this.listQuery.endDate,
        adult: this.listQuery.adult,
        child: this.listQuery.child,
      },
    });
  }

  private handleAddFavoriteItem(idx: any) {
    if (UserModule.token) {
      addFavoriteItem({ stayIdx: idx }).then(() => {
        this.$message.success('저장되었습니다.');
      });
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
  }

  private handleMoreStayList($state: any) {
    this.listQuery.page += 1;
    this.loading = true;
    getStayList(this.listQuery).then((res) => {
      this.loading = false;
      this.stayList = this.stayList.concat(res.data.content);
      if (res.data.content.length > 0) {
        $state.loaded();
      } else {
        this.isNoMore = true;
        $state.complete();
      }
    }).catch((error) => {
      this.$message.error('숙소를 불러오는데 실패했습니다.');
      this.loading = false;
      $state.complete();
    });
  }

  private getFilterList() {
    const filterList: any = [];

    this.searchCategories.forEach((category: any) => {
      const index = this.stayCategoryList.findIndex((stayCategory: any) => stayCategory.idx === category);
      if (index > -1) filterList.push(this.stayCategoryList[index].name);
    });

    this.searchOptions.forEach((option: any) => {
      this.optionTypeList.forEach((optionType: any) => {
        const index = optionType.options.findIndex((stayOption: any) => stayOption.idx === option);
        if (index > -1) filterList.push(optionType.options[index].name);
      });
    });

    return filterList;
  }

  private handleApplyFilter() {
    this.filterOptionVisible = false;
    this.handleSearch();
    this.formQuery.filterCnt = this.getFilterList().length; /* 검색영역의 선택한 필터 갯수 */
  }

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }

  private calendarLoading = false;

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.formQuery.startDate = startDate;
    this.formQuery.endDate = endDate;
  }

  private filterOptionChange() {
    this.filterOptionVisible = !this.filterOptionVisible;
  }

  private favoriteFlag = false;

  private async handleAddFavoriteStay(event: Event, idx: any, type: number) {
    if (this.favoriteFlag) {
      this.$message.info('이전 동작이 실행 중 입니다.');
      return;
    }
    this.favoriteFlag = true;
    if (UserModule.token) {
      const target = (event.target as HTMLElement)?.parentElement?.parentElement?.parentElement?.parentElement;
      if (target?.classList.contains('active')) {
        if (target.getAttribute('data-id')) {
          await deleteFavoriteItem(target.getAttribute('data-id')).then(() => {
            this.$message.success('삭제되었습니다.');
            target.classList.remove('active');
            target.removeAttribute('data-id');
          });
        } else {
          this.$message.info('해당 서비스를 찾을 수 없습니다.');
        }
      } else if (target && !(target?.classList.contains('active'))) {
        let query = {};
        if (type === 1) {
          query = { stayIdx: idx };
        } else if (type === 2) {
          query = { storeInfoIdx: idx };
        }
        await addFavoriteItem(query).then((res) => {
          this.$message.success('저장되었습니다.');
          target.classList.add('active');
          target.setAttribute('data-id', res.data);
        });
      }
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
    this.favoriteFlag = false;
  }

  private stayNavigateToDetail(stayIdx: string) {
    this.$router.push({ name: 'StayDetail', params: { stayIdx } });
  }

  // 버튼토글
  private curTabs: boolean[] = [false, false, false, false];

  private toggleSection(index: number) {
    const iconElement = this.$refs[`icon${index}`] as HTMLElement;

    if (this.curTabs[index]) {
      this.curTabs[index] = !this.curTabs[index];
      const array = this.curTabs.concat();
      this.curTabs = array;
    } else {
      this.curTabs[index] = !this.curTabs[index];
      const array = this.curTabs.concat();
      this.curTabs = array;
    }
  }
}
