import request from '@/utils/request';

const PATH = '/stay/option/type';

export const getStayOptionTypeList = () => request({
  url: PATH,
  method: 'get',
});

export const getStayOptionTypeListByRoom = () => request({
  url: `${PATH}/room`,
  method: 'get',
});
